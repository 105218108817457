import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import VideoModal from '../Modals/VideoModal'

function Header() {
	const [modalShow, setModalShow] = React.useState(false);

	return(
		<Navbar expand="lg" bg="dark" variant="dark" className="justify-content-between">
		  <Navbar.Brand href="#home">Life Appropriation Act</Navbar.Brand>
		  <Navbar.Toggle aria-controls="basic-navbar-nav"/>
		  <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
		    <Nav>
		      <Nav.Link onClick={() => setModalShow(true)}>About</Nav.Link>
		      <Nav.Link href="#Legislation">Legislation</Nav.Link>
		      <Nav.Link href="#Hearings">Contact</Nav.Link>
		    </Nav>
		  </Navbar.Collapse>
		  <VideoModal
		        show={modalShow}
		        onHide={() => setModalShow(false)}
			/>
		</Navbar>
	)
}


export default Header;