import React from 'react';
import './about.css'


function About() {

	return(
		<header>
			<section className="about-section overlay">
				<div>
				    <div style={{'textAlign': 'center', paddingTop: '20px'}} >
				      <div className="text-white">
				        <h1>Life Appropriation Act</h1>
				      </div>
				      <div className="mainVideo">
				      	<iframe title="main-video" width="100%" height="100%" src="https://www.youtube.com/embed/AO-hJxkBc4I" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>	
				      </div>
				    </div>
				</div>
			</section>
		</header>
	)
}


export default About;