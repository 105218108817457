import React from 'react';
import './Hearings.css';

function Hearings() {
	return(
		<div className="hearings" >
			<div>
			  <h2>Hearings on the Bill</h2>
			  <div className="videos">
			    <iframe 
			    	className="content"
			    	width="100%" height="100%"
			    	title="Hearing on the Life Appropriation Act"
			    	src="https://www.youtube.com/embed/wVDrnFTwJJo" 
			    	frameBorder="0" 
			    	allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
			    	allowFullScreen
			    >
			    </iframe>
			  </div>
			</div>
			<div style={{ width: '90%', margin: '0 auto', paddingTop: '20px'}}>
				<h2>
					Law for Attorney General
				</h2>
				<h5 style={{paddingTop: '10px'}}>
					<a href="https://www.dropbox.com/s/15h7z4vadplh7sl/pacer%20Motion%20to%20Intervene%20Title%20X%20case.pdf?dl=0">Brief by De Facto Attorneys General in California v. U.S. Department of Public Health, 3:19-cv-01184-EMC (ND Cal. 2019)</a>
				</h5>
			</div>
		</div>
	)
}


export default Hearings;

