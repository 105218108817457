import React from 'react'
import Row from 'react-bootstrap/Row'
import Figure from 'react-bootstrap/Figure'
import Card from 'react-bootstrap/Card'

export default function StateInfo(props) {
  const item = props.info

  return (

        <Card bg="light">
          <Card.Body>
            <Card.Title className="text-center">
              <h2>{item.state}</h2>
            </Card.Title>
            <hr />
            <Card.Text>
              <Row className="justify-content-center h5">
                <a href={item.leg_link} target='_blank' rel="noopener noreferrer">{item.link_text}</a>
                </Row>
              <Row className="justify-content-center h5">
                <a href={item.tp_link} target='_blank' rel="noopener noreferrer">Talking Points</a>
                </Row>
                <Row className="justify-content-center h5">
                  Prime Sponsor(s):
                </Row>
              </Card.Text>
                {
                  (item.sponsors.length > 0) ?
                    item.sponsors.map(sponsor => (
                        <Figure key={sponsor.name} style={{height: 100, paddingRight: 3}}>
                          <Figure.Image 
                            src={sponsor.photo}
                            width={100}
                            alt={sponsor.name}
                          />
                          <Figure.Caption>
                            <a 
                              style={{textAlign: 'center'}}
                              href={sponsor.url} target='_blank' 
                              rel="noopener 
                              noreferrer"
                            >
                              {sponsor.name}
                            </a>
                          </Figure.Caption>
                        </Figure>
                    ))
                  :
                    <Row className="justify-content-center">
                      <p style={{textAlign: 'center'}}>Not Yet</p>
                    </Row>
                }
          </Card.Body>
       </Card>
  );
}