import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './components/Header/header'
import About from './components/About/about'
import States from './components/States/states'
import Footer from './components/Footer/footer'
import Hearings from './components/Hearings/Hearings'

function App() {
  return (
  	<div className="App">
	    
      <Header />
      <About />
      <Hearings />
      <States />
      <Footer />
	   
	</div>
  );
}

export default App;
