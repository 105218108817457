import React from 'react';
import Container from 'react-bootstrap/Container'

function Header() {

	return(
		<section style={{display: 'flex', width: '100%', backgroundColor: 'hsl(0,0%,96%)', paddingTop: '50px', paddingBottom: '50px',}}>
			<Container>
				<div id="Hearings" >
					<h3>Contact us for more information at:</h3>
					<p>De Facto Attorney Generals <br/>
					Special Forces Of Libertys <br/>
					Clean Services Foundation <br/>
					<a href="mailto:johnjr@tel-electronics.com">johnjr@tel-electronics.com</a><br/>
					(801) 654-5973</p>
				</div>
			</Container>
		</section>
	)
}


export default Header;