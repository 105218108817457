import React from 'react'
import Data from './data'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import StateInfo from './StateInfo'
import './states.css'

export default class States extends React.Component {
	
	constructor(props){
	    super(props);
	    this.state = {
	      term: '',
	      data: Data,
	      modalShow: false,
	      stateClicked: 'Alabama',
	    }

	    this.handleTermChange = this.handleTermChange.bind(this);
	    this.byState = this.byState.bind(this);
	    this.getStateData = this.getStateData.bind(this);
	  }

	  handleTermChange(event){
	    this.setState({
	      term: event.target.value,
	    });
	    console.log(this.state.term)
	  }

	  byState(item){
    	return item.state.toLowerCase().includes(this.state.term.toLowerCase());
      }

      getStateData(place) {
      	const data = this.state.data.filter(item => item.state === place);
      	console.log(data[0].state);
      	return data;
      }

	render() {

		return(
			<Container id="Legislation" style={{paddingBottom: '20px'}}>
				<Row style={{ paddingTop: '20px', paddingBottom: '20px', }} className="justify-content-center">
					<h3>Legislation By State</h3>
				</Row>
				<Row>
					<Form inline style={{ margin: "0 auto", paddingBottom: '20px' }} className="justify-content-center give-space">
				      <FormControl type="text" placeholder="Search"  onChange={this.handleTermChange} />
				    </Form>
				</Row>
				{
				  this.state.data.filter(this.byState).map(item => (
					  	
					  	<StateInfo info={item} key={item.state}/>
					  	
					)
				  )
				}
			</Container>
		)
	}
}