const talking_points = 'https://www.dropbox.com/s/kbnvauggn11z31m/TALKING%20POINTS%20FOR%20THE%20LIFE%20APPROPRIATION%20ACT.pdf?dl=0';
const link_text = 'Text of Legislation'

const Data= [
	{
		"state": "Alabama",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/4ygl02ljxlre5zk/Alabama%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Alaska",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/4lte2xoaimwluzi/Alaska%20Life%20Appropriation%20Act%20%281%29.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Arizona",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/8rn3ca3a4ywf9aq/Arizona%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Arkansas",
		"is_conf": false,
		"leg_link": "#",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "California",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/vzngywxbroehr5x/California%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Colorado",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/w1zauk357p8fxgk/Colorado%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Connecticut",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/tjzp9gdi6a4a0l9/Connecticut%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Delaware",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/7lv04ft3gdqzxj6/Delaware%20Life%20Appropriation%20Act%20%281%29.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [
			{
				"name": "Rep. Smyk",
				"url": "https://legis.delaware.gov/AssemblyMember/150/Smyk",
				"photo": 'https://legis.delaware.gov/images/default-source/default-album/StephenSmyk.jpg?sfvrsn=1a9fb038_0',
			},
			{
				"name": "Sen. Richardson",
				"url": "https://legis.delaware.gov/AssemblyMember/150/Richardson",
				"photo": 'https://legis.delaware.gov/images/default-source/Legislators/BryantRichardson.jpg?sfvrsn=628db038_2',
			}
		],
	},
	{
		"state": "Florida",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/9ljcvx7eq6ab8qk/Florida%20Life%20Appropriation%20Act%20%281%29.pdf?dl=0",
		"link_text": 'Text as Introduced - SB 1126',
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Georgia",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/612gyc0p51x0p69/Georgia%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Hawaii",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/oc1rih7cq3kqawk/Hawaii%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Idaho",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/0pg1npq3zatbmzs/Idaho%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Illinois",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/5efpcudkav9w49l/Illinois%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Indiana",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/1s90h4uve9so3il/Indiana%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Iowa",
		"is_conf": false,
		"leg_link": "",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Kansas",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/wo5z45lxrsp7hm0/Kansas%20Stop%20Social%20Media%20Censorship%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [
			{
				"name": "Sen. Rucker",
				"url": "http://www.kslegislature.org/li/b2019_20/members/sen_rucker_eric_1/",
				"photo": "http://www.kslegislature.org/li/m/images/pics/sen_rucker_eric_1.jpg",
			}
		],
	},
	{
		"state": "Kentucky",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/il4fv08p6k3zy7k/Kentucky%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [
			{
				"name": "Sen. Castlen",
				"url": "https://legislature.ky.gov/Legislators/Pages/Legislator-Profile.aspx?DistrictNumber=108",
				"photo": 'https://legislature.ky.gov/Legislators%20Thumbnail%20Images/senate108.jpg',
			}
		],
	},
	{
		"state": "Louisiana",
		"is_conf": false,
		"leg_link": "#",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Maine",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/hsnilplohz4effc/Maine%20Life%20Appropriation%20Act%20%20%281%29.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Maryland",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/ix7ciz9pdw3goyw/Maryland%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Massachusetts",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/jzmky8eak05i3h3/Massachusetts%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Michigan",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/6yqcgviqu3ve5gj/Michigan%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Minnesota",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/70xoy14nqvfi281/Minnesota%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Mississippi",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/qalji7pty8iytcd/Mississippi%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [
			{
				"name": "Rep. Dan Eubanks",
				"url": "http://billstatus.ls.state.ms.us/members/house/eubanks.xml",
				"photo": "http://billstatus.ls.state.ms.us/members/house/eubanks.jpg",
			}
		],
	},
	{
		"state": "Missouri",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/8u3yn0nqesx2n1d/Missouri%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [
			{
				"name": "Rep. Kelley",
				"url": "https://house.mo.gov/memberdetails.aspx?district=127&year=2019&code=R",
				"photo": 'https://house.mo.gov/MemberPhoto.aspx?id=2051',
			}
		],
	},
	{
		"state": "Montana",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/p4puu49b9fe1zrp/Montana%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Nebraska",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/t19tjdmk52inmlw/Nebraska%20Life%20Appropriation%20Act%20%281%29.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Nevada",
		"is_conf": false,
		"leg_link":"#",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "New Hampshire",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/6scg0o088ni3zz4/New%20Hampshire%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "New Jersey",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/9c3rgfk7ga56dxv/New%20Jersey%20Life%20Appropriation%20Act%20%281%29.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "New Mexico",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/urw2g6emgckbb9n/New%20Mexico%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "New York",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/rxviz62r6gbar4u/New%20York%20Life%20Appropriation%20Act%20short%20version.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "North Carolina",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/bltxhf89scz792v/North%20Carolina%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [
			{
				"name": "Rep. Hanig",
				"url": "https://www.ncleg.gov/Members/Biography/H/745",
				"photo": "https://www.ncleg.gov/Members/MemberImage/H/745/Low",
			},
		],
	},
	{
		"state": "North Dakota",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/zxg9fxcyhp0iwo9/North%20Dakota%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Ohio",
		"image": "./state_icons/icons8-ohio-500.png",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/r6i0ks1yay2zkrg/Ohio%20Stop%20Social%20Media%20Censorship%20Act%20%281%29.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Oklahoma",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/9z9vwqh6w1c9hnj/New%20Oklahoma%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [
			{
				"name": "Sen. Silk",
				"url": "http://www.oksenate.gov/Senators/biographies/silk_bio.html",
				"photo": 'http://www.oksenate.gov/Senators/biographies/silk_bio.jpg',
			},
		],
	},
	{
		"state": "Oregon",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/prhixeh4laadm7h/Oregon%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Pennsylvania",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/6f6soxfxehyqi1h/Pennsylvania%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Rhode Island",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/3bi3o16gc5mxttw/Rhode%20Island%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [
			{
				"name": "Rep. Price",
				"url": "http://www.rilin.state.ri.us/representatives/price/default.aspx",
				"photo": 'http://www.rilin.state.ri.us/representatives/pictures/price.jpg',
			},
		],
	},
	{
		"state": "South Carolina",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/gvam15dpp1811qi/South%20Carolina%20LIfe%20Appropration%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "South Dakota",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/y2i2n4u24hrqazc/South%20Dakota%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [
		],
	},
	{
		"state": "Tennessee",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/hqintg7ynv03cgu/Tennessee%20Life%20Appropriation%20Act%20%281%29.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [
			{
				"name": "Rep. Ragan",
				"url": "https://ballotpedia.org/John_Ragan",
				"photo": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/John_Ragan.jpg",
			},
			{
				"name": "Sen. Jackson",
				"url": "https://ballotpedia.org/Ed_Jackson",
				"photo": "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/Ed_Jackson.jpg",
			},
		],
	},
	{
		"state": "Texas",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/0w48yhbv08yol99/Texas%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": []
	},
	{
		"state": "Utah",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/71n9u9ojnt5xq53/Utah%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [
			{
				"name": "Rep. Pierucci",
				"url": "https://house.utah.gov/2017site/rep/PIERUC",
				"photo": "https://le.utah.gov/images/legislator/PIERUC.jpg",
			},
		]
	},
	{
		"state": "Vermont",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/3hp7x5sy1gpezau/Vermont%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Virginia",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/e4k3p7myum7ffpz/Virginia%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Washington",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/4vvrcnt74wq5ww8/Washington%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "West Virginia",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/47upbz64ifqayxc/West%20Virginia%20Life%20Appropriation%20Act%20State%20Consitution.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [
			{
				"name": "Sen. Maynard",
				"url": "https://www.wvlegislature.gov/Senate1/lawmaker.cfm?member=Senator%20Maynard",
				"photo": 'https://www.wvlegislature.gov/images/members/2020/senate/maynard_mark.jpg',
			},
			{
				"name": "Del. Graves",
				"url": 'http://www.wvlegislature.gov/House/lawmaker.cfm?member=Delegate%20Graves',
				"photo": "http://www.wvlegislature.gov/images/members/2020/house/graves_dianna.jpg",
			}
		],
	},
	{
		"state": "Wisconsin",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/fbz3e25oqt0as6k/Wisconsin%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
	{
		"state": "Wyoming",
		"image": "./state_icons/icons8-wyoming-500.png",
		"is_conf": false,
		"leg_link": "https://www.dropbox.com/s/s4vafla4u3vi98f/Wyoming%20Life%20Appropriation%20Act.pdf?dl=0",
		"link_text": link_text,
		"tp_link": talking_points,
		"sponsors": [],
	},
]

export default Data;
